import moment from 'moment-timezone'
import _ from 'lodash'

import { AG_GridCustomizedTableHeaders_Key, openFdaRecalls_Headers } from '../../../../misc/tableHeaders'
import fdaServices from '../../../../services/notisphere/fda'

export default {
    data() {
        return {
            total: 0,
            items: [],
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
                getContextMenuItems: this.getContextMenuItems,
            },
            gridApi: null,
            columnApi: null,
            delay: 700,
            recall_type: 'device',
            clicks: 0,
            timer: null,
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
            customDefaultColDef: {
                flex: 1,
                minWidth: 100,
                enableValue: true,
                enableRowGroup: false,
                enablePivot: true,
                sortable: true,
                resizable: true,
                filter: true,
                editable: false,
                wrapText: false,
                autoHeight: false,
                wrapHeaderText: false,
                autoHeaderHeight: false,
                filterParams: {
                    suppressAndOrCondition: true,
                    buttons: ['apply', 'reset'],
                    closeOnApply: true,
                },
                menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
            },
            recallData: null,
            globalSearchText: '',
            lastUpdated: '',
            modalTitle: 'Recall Details',
            modalUdiFieldHeader: [
                {
                    key: 'udi',
                    label: 'UDI'
                },
                {
                    key: 'normalized_udi',
                    label: 'Normalized UDI',
                },
                {
                    key: 'is_valid',
                    label: 'Is Valid'
                },
                {
                    key: 'all_lots',
                    label: 'All Lots'
                },
                {
                    key: 'lots',
                    label: 'Lots'
                },
                {
                    key: 'is_kit',
                    label: 'Is Kit'
                },
                {
                    key: 'is_implantable',
                    label: 'Is Implantable'
                }
            ],
        }
    },
    beforeMount() {
        this.columnDefs = openFdaRecalls_Headers
    },
    created() {
        this.rowModelType = 'serverSide'
        this.fetchMetaData()
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Notisphere_Login.OpenFDA_Recall_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        setFilterModel(params) {
            const tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            const filterModel = tableState?.Notisphere_Login?.OpenFDA_Recall_Filters || {}
            params.api.setFilterModel(filterModel)
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Notisphere_Login?.OpenFDA_Recall_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            const filterModel = event.api.getFilterModel()
            this.table_Connection_Data.Notisphere_Login.OpenFDA_Recall_Filters = filterModel
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        fetchData(params) {
            let that = this
            this.applyState(params)
            this.setFilterModel(params)
            that.gridApi = params.api
            that.columnApi = params.columnApi
            localStorage.setItem(
                'agGridColumState',
                JSON.stringify(params.columnApi.getColumnState())
            )
            localStorage.setItem(
                'agGridFilterModel',
                JSON.stringify(params.api.getFilterModel())
            )
            const filterModel = that.gridApi.getFilterModel()
            that.searchQuery.colId = params.request?.sortModel[0]?.colId
            that.searchQuery.sort = params.request?.sortModel[0]?.sort
            const datasource = {
                getRows(params) {
                    fdaServices.getAllFdaRecalls({
                        search: that.globalSearchText,
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        filterModel
                    }).then((resp) => {
                        if(resp && resp.data) {
                            that.items = resp.data.data
                            that.total = resp.data.totalRecords
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            params.successCallback(resp.data.data)
                        } else {
                            params.failCallback()
                            that.total = 0
                        }
                    })
                }
            }
            params.api.setServerSideDatasource(datasource)
        },
        fetchMetaData() {
            fdaServices.getFdaRecallMetaData().then(resp => {
                if(resp && resp.data) {
                    this.lastUpdated = `${moment(resp.data.data.completed_date).local().format('MM/DD/YY')}`
                } else {
                    this.lastUpdated = ''
                }
            })
        },
        onCellClicked({ colDef, data }) {
            if (colDef && colDef.field === 'recall_link') {
                data.event.preventDefault()
            } else {
                fdaServices.getFdaRecall(data._id).then((resp) => {
                    if(resp && resp.data) {
                        this.recallData = { ...data, ...resp.data.data }
                    }
                    if (this.recallData != null) {
                        this.$nextTick(()=>{
                            this.$refs.recallFdaModal.show()
                        })
                    }
                })
            }
        },
        getContextMenuItems(params) {
            let that = this
            const defaultItems = params.defaultItems
            const filteredItems = defaultItems.filter((item) => item !== 'export')
            const customMenuItem = {
                name: 'Excel Export',
                action: async function () {
                    const gridColState = JSON.parse(
                        localStorage.getItem('agGridColumState')
                    )
                    const gridFilterModel = JSON.parse(
                        localStorage.getItem('agGridFilterModel')
                    )
                    const sort = gridColState.filter((s) => s.sort !== null)
                    const email = that.$store.state.user.email
                    const query_param = {
                        search_query: that.globalSearchText,
                        sortField: sort.length !== 0 ? sort[0].colId : null,
                        sortDirection: sort.length !== 0 ? sort[0].sort : null,
                        filter_model: gridFilterModel,
                        email: email,
                        recall_type: that.recall_type
                    }
                    const resp = await fdaServices.exportAllFdaRecalls(query_param)
                    that._showOk(resp.data.body, {
                        variant: 'success',
                        title: 'Message',
                        buttonTitle: 'Ok',
                    })
                },
                icon: '<span class="ag-icon ag-icon-excel"></span>',
            }

            const allItems = [...filteredItems, customMenuItem]
            return allItems
        },
        debounceInput: _.debounce(function (searchStr) {
            this.searchQuery.pageNumber = 1
            if (searchStr.length > 2) {
                this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
            } else if (searchStr.length === 0) {
                this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
            }
        }, 500),
        onGlobalSearchChange(inputVal) {
            this.globalSearchText = inputVal
            this.debounceInput(inputVal)
        }
    }
}
